import React from "react"

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex align-items-center justify-content-center m-auto vh-100">
                {/* <div className="d-flex">
                    <img src={logo} className="mr-auto ml-auto mt-2" style={{maxWidth: "200px"}} alt="شرکت توسعه نرم افزار باران"/>
                </div>*/}
                <div className="loader"></div>
            </div>
        )
    }
}

export default SpinnerComponent
